.login-body {
    height: 100vh;
    background-image: url("/assets/fromlabs/background.png");
    // background-repeat: repeat!important;
    // background-size: 10px;
    .fromlabs-logo-image{
        top: 5px;
        left: 5px;
        position: absolute;
        img{
            width: 40%;
        }
    }
    .login-panel{
        // border: 1px solid #8faadc;
        height: unset!important;
        border-radius: 20px;
        z-index: 1000;
        .login-panel-content{
            // padding: 2px 100px !important;
            h1 {
                font-size: xx-large!important;
                color: #1ca7ec;
                font-weight: unset;
                margin-bottom: -20px;
                margin-top: -25px;
            }
            .btn-sign-in {
                background-color: #1ca7ec;
                border-radius: 30px;
                color: white;
            }
            .btn-sign-in:hover{
                background-color: #7bd5f5;
            }
            .btn-create-account {
                background-color: transparent;
                border: 1px solid #a0a0a0;
                box-shadow: none;
                border-radius: 30px;
                color: black;
                transition: 0.5s;
            }
            .btn-create-account:hover{
                background-color: #7bd5f5;
                color: white;
                border: 1px solid  #7bd5f5;
                
            }
        }
        .fl-login-header{
            padding: 4%;
            background-color: transparent;
            // background-image: linear-gradient(to top right,#4adede, #1ca7ec );
            border-radius: 30px 30px 0px 0px;
            img {
                width: 50%!important;
            }
        }
        @media(max-width:576px){
            width: 80%;
            padding: none !important;
            margin-left: 10%;
            margin-right: 10%;
        }
    }
    .login-background-image{
        position: absolute;
        bottom: 0px;
        width: 100%;
        img {
            width: 100%;
        }
    }
}

.reset-password {
    margin-left:15px;
}

.recover-username {
    margin-left:15px;
    margin-top:3px;
    height:3vh;
}

.message-login{
    padding-top: unset!important;
    padding-bottom: unset!important;
    color:black !important;
    text-decoration-color: black;
}
