#filter-menu {
    legend#filter-menu-legend {
        background: white!important;
        border-radius: 15px!important;
        box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.19)!important;
        padding: unset!important;
        padding-left: unset!important;
        padding-right: 1.5em!important;
        a {
            padding: unset!important;
        }
    }
}

.filter-menu {
    legend.filter-menu-legend {
        background: white !important;
        border-radius: 15px !important;
        box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.19)!important;
        padding: 5px 1em!important;
        color: #1ca7ec;
    }
}

.filter-with-toggle{
    legend{
        
        background: white!important;
        border-radius: 15px!important;
        box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.19)!important;
        padding: unset!important;
        padding-left: unset!important;
        padding-right: 1.5em!important;
        a {
            padding: unset!important;
            .p-fieldset-toggler {
                color: white!important;
            }
        }
    }
}

.layout-wrapper{
    .layout-sidebar.layout-sidebar-dark{
        // background-image: url("/assets/fromlabs/background.png");
        // background-repeat: repeat !important;
        // background-size: 10px;
        background: #dde7ee!important;
        // background:var(--theme-page-background);    
        .layout-menu {
            li {
                a {
                    // border: 3px solid white!important;
                    
                    // border-radius: 10px;
                    i  {
                        color: black!important;
                    }
                }

                .active-menuitem {
                    a {
                        border: 3px solid linear-gradient(to top right, #d2f1f1, white) !important;
                        border-radius: 10px;
                        background: linear-gradient(to top right, #4adede, #1ca7ec) padding-box!important;
                    }
                }
            }
        }
    }
    .layout-sidebar {
        width: 240px;
        // left: -200px;
        .sidebar-logo {
            text-align: center;
            border-bottom: 0.5px solid black;
            img.app-logo{
                margin-left: unset;
            }
        }
        .sidebar-anchor1 {
            background-image: url("/assets/fromlabs/icons/icon-pushpin.png");
            background-repeat: no-repeat;
            padding-top: 5px !important;
            padding-bottom: 15px !important;
            padding-left: 28px !important;
            margin-left: 15px !important;
            cursor: pointer;
            transition: 0.5s;
            &:hover {
                background-image: url("/assets/fromlabs/icons/icon-pushpin-alt.png");
                background-repeat: no-repeat;
            }
            &:focus {
                outline: unset;
            }
        }
        .layout-menu {
            li {
                padding: 4px 8px 4px 10px;
                a {
                    border: 2px solid var(--theme-page-text);
                    border-radius: 10px;
                    transition: 0.5s;
                    margin:1px;
                    margin-left: 0px;
                    margin-right: 15px;

                    
                // border: 3px solid black;
                border-radius: 10px;
                background: white;

                    .menuitem-text {
                        color: black!important;
                    }
                    // background: 
                    //     linear-gradient(#ffffff,#ffffff) padding-box,
                    //     // linear-gradient(to right,#0e7dc9, #0e7dc9) border-box;
                    
                    &:hover, &:focus{
                        border: 2px solid transparent;
                        border-radius: 10px;
                        color: var(--theme-page-text) !important;
                        background: 
                            // linear-gradient(#ffffff,#ffffff) padding-box,
                            linear-gradient(to top right,#4adede, #1ca7ec ) padding-box,
                            linear-gradient(to top right, #4adede, #1ca7ec) border-box;
                        .menuitem-text {
                            color: black !important;
                        }                    
                    }
                    i {
                        float: right;
                        width: 25px;
                        height: 23px;
                        font-size: 24px;
                        position: absolute;
                        right: 17px;
                        top: 50%;
                        margin-top: -13px;
                        background-repeat: no-repeat;
                        background-size: contain;
                        background-position: center;
                    }
                    i.layout-submenu-toggler {
                        top: 20px;
                        right: 0px;
                        width: 19px;
                        height: 19px;
                    }
                }
                a.ripplelink {
                    border: 3px solid transparent;
                    border-radius: 10px;
                    &:focus {
                        border: 3px solid white;
                        background: 
                            linear-gradient(to top right,#4adede, #1ca7ec ) padding-box,
                            linear-gradient(to top right, #4adede, #1ca7ec) border-box;
                            // linear-gradient(#ffffff,#ffffff) padding-box,
                            // linear-gradient(to right,#0e7dc9,#0e7dc9) border-box;
                    }
                    outline: unset;
                }
            }
            li.active-menuitem {
                a.active-menuitem-routerlink{
                    border: 3px solid white;
                    border-radius: 10px;
                    background: 
                        linear-gradient(to top right,#4adede, #1ca7ec ) padding-box;
                        // linear-gradient(to top right, #4adede, #1ca7ec) border-box;
                    // background: 
                    //     linear-gradient(#ffffff,#ffffff) padding-box,
                    //     linear-gradient(to right, #f7941e, #f7941e) border-box;
                    .menuitem-text {
                        color: black !important;
                        font-weight: bold;
                    }
                }
                a.ripplelink {
                    border: 3px solid white;
                    border-radius: 10px;
                    background: 
                    //     linear-gradient(to top right,#4adede, #1ca7ec ) padding-box,
                    //     linear-gradient(to top right, #4adede, #1ca7ec) border-box;
                        // linear-gradient(#ffffff,#ffffff) padding-box,
                        linear-gradient(to right, #f7941e, #f7941e) border-box;
                }
                // i{
                //     filter: opacity(.5) drop-shadow(0 0 0 #5AAF4B);
                // }
            }
        }

        .p-scrollpanel-content {
            overflow-x: unset;
        }
        
        .p-scrollpanel-bar-x {
            display: none;
        }

        .p-scrollpanel-background-image{
            position: relative;
        }

        .p-scrollpanel-background-image::before{
            content: " ";
            background-image: url("/assets/fromlabs/sidebar-image-copy.png");
            // background-image: linear-gradient(to top right,#c3c3c3, #ffffff );
            background-repeat: no-repeat;
            background-size: 100%;
            background-position: center bottom;
            position: absolute;
            width: 100%;
            bottom: 0px;
            height: 100%;
            left: 0;
        }
    }

    .layout-main {
        .layout-breadcrumb {            
            @media(min-width:1025px){
                padding-top: 0;
            }
        }
        .layout-topbar{
            // background: whitesmoke;
            // height: 63px;
            // font-size: 12px;
            // .topbar-header-logo{
            //     height: 42px;
            // }
            @media(min-width:1025px){
                display: none;
            }
            // @media(max-width:768px){
            //     height: 63px;
            //     .topbar-header-logo{
            //         height: 40px;
            //     }
            // }
            // @media(max-width:576px){
            //     height: 56px;
            //     .topbar-header-logo{
            //         height: 32px;
            //     }
            // }
            // .menuitem-text{
            //     color: black;
            // }
            // .layout-topbar-menu-wrapper {
            //     .topbar-menu > li {
            //         .topbar-action-logout i {
            //             background-color: tomato;
            //             border-radius: 100%;
            //             transition: 0.5s;
            //             margin-left: 10px;
            //             &:hover {
            //                 background-color: transparent;
            //                 color: #FF3333;
            //             }
            //         }
            //     }
            // }
        }

        .layout-content {
            // padding-top: 100px;
            // overflow-y: auto;
            // height: 100vh;

            @media(max-width:1023px){
                padding-top: 80px;
            }
        }
    }
} 