/* Data list table */
.customer-badge {
    border-radius: 2px;
    padding: 0.25em 0.5em;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;
}

.customer-badge.action-approved {
    background-color: #c8e6c9;
    color: #256029;
}

.customer-badge.action-pending {
    background-color: #feedaf;
    color: #8a5340;
}

.customer-badge.action-revoked {
    background-color: gray;
    color: black;
}

.customer-badge.action-rejected {
    background-color: red;
    color: white;
}

.customer-badge.role-supervisor {
    background-color: #003152;
    color: white;
}

.customer-badge.role-trainee {
    background-color: #B3E5FC;
    color: #23547B;
}

.customer-badge.status-Activated {
    background-color: #c8e6c9;
    color: #256029;
}

.customer-badge.status-Rejected {
    background-color: red;
    color: white;
}

.customer-badge.status-Active {
    background-color: #c8e6c9;
    color: #256029;
}

.customer-badge.status-Suspended {
    background-color: red;
    color: white;
}

.customer-badge.status-Revoked {
    background-color: gray;
    color: black;
}

.customer-badge.status-Pending {
    background-color: #feedaf;
    color: #8a5340;
}

.customer-badge.status-rejected {
    background-color: red;
    color: white;
}

.customer-badge.status-revoked {
    background-color: gray;
    color: black;
}

.customer-badge.status-activated {
    background-color: #c8e6c9;
    color: #256029;
}

.customer-badge.status-pending {
    background-color: #feedaf;
    color: #8a5340;
}

.customer-badge.status-approved {
    background-color: #c8e6c9;
    color: #256029;
}


.customer-badge.status-qualified {
    background-color: #c8e6c9;
    color: #256029;
}
.customer-badge.status-unqualified {
    background-color: #ffcdd2;
    color: #c63737;
}
.customer-badge.status-negotiation {
    background-color: #feedaf;
    color: #8a5340;
}
.customer-badge.status-new {
    background-color: #b3e5fc;
    color: #23547b;
}
.customer-badge.status-renewal {
    background-color: #eccfff;
    color: #694382;
}
.customer-badge.status-proposal {
    background-color: #ffd8b2;
    color: #805b36;
}
.p-multiselect-representative-option {
    display: inline-block;
    vertical-align: middle;
}
.p-multiselect-representative-option img {
    vertical-align: middle;
    width: 24px;
}
.p-multiselect-representative-option span {
    margin-top: 0.125em;
}
.p-paginator .p-dropdown {
    float: left;
}
.p-paginator .p-paginator-current {
    float: right;
}
.p-progressbar {
    height: 8px;
    background-color: #d8dadc;
}
.p-progressbar .p-progressbar-value {
    background-color: #00acad;
    transition: none;
}
.p-column-filter {
    display: block;
}
.p-column-filter input {
    width: 100%;
}
.p-datatable-globalfilter-container {
    float: right;
}
.p-datatable-globalfilter-container input {
    width: 200px;
}
.p-datepicker {
    min-width: 25em;
}
.p-datepicker td {
    font-weight: 400;
}
.p-datatable.p-datatable-customers .p-datatable-header {
    border: 0 none;
    padding: 12px;
    text-align: left;
    font-size: 20px;
}
.p-datatable.p-datatable-customers .p-paginator {
    border: 0 none;
    padding: 1em;
}
.p-datatable.p-datatable-customers .p-datatable-thead > tr > th {
    border: 0 none;
    text-align: left;
}
.p-datatable.p-datatable-customers .p-datatable-thead > tr > th.p-filter-column {
    border-top: 1px solid #c8c8c8;
}
.p-datatable.p-datatable-customers .p-datatable-tbody > tr > td {
    border: 0 none;
    cursor: auto;
}
.p-datatable.p-datatable-customers .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
}


/* --------- Tree Node ----------- */
.tree-node-sidebar {
    width: 25%;
    max-width: 30%;
}

.rst__rowWrapper {
    height: 115%!important;
    width: 630px;
}

body .p-component-overlay {
    background-color: transparent!important;
}

.loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #607D8B; /* Blue */
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 2s linear infinite;
    margin-left: 150px;
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.tooltip {
    position: relative;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 150px!important;
    background-color: black;
    color: #fff!important;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    font-size: small!important;

    position: absolute;
    z-index: 1;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

.node-label {
    display:inline-block;
    padding-left:8px;
    padding-right:8px;
    text-align:center;
    border-radius:4px;
    color:black!important;
    /* background-color:#4CAF50!important; */
}

.node-border {
    border:1px solid black!important;
    border-color:black!important
}

.pointer {cursor: pointer;}

.btn-previous {
    background-color: #003152;
    color: white;
    border: .5px solid black;
    font-size: 1.1rem;
    border-radius: 60%;
    padding-right: 10px;
    cursor: pointer;
    margin-left: 5px;
    margin-right: 2px;
}

.btn-next {
    background-color: #003152;
    color: white;
    border: .5px solid black;
    font-size: 1.1rem;
    border-radius: 60%;
    padding-left: 10px;
    cursor: pointer;
    margin-left: 2px;
    margin-right: 5px;
}

/* -------------------------- */

@media only screen and (max-width: 640px) {
    .sns-game-list-header {
        text-align: unset!important;
    }

    .sns-game-moving {
        width: 80vw!important;
        max-width: 80vw!important;
    }

    .sns-moving-to {
        width:45vw!important;
    }

    .sns-moving-action {
        width: 55vw!important;
    }

    .sns-game-list-filter {
        width: 60vw!important;
        max-width: 60vw!important;
    }

    #sns-lbl-exception {
        margin-right: 35px!important;
    }

    #layout-content-container {
       /* padding: 0px!important; */
    }

    #sns-resource-list-container {
        height: 25vh!important;
    }

    #sns-resource-downloads-container {
        height: 35vh!important;
    }

    #sns-resource-player-content-wrapper {
        height: 75vh!important;
        max-height: 75vh!important;
    }

    .sns-resource-player-checkbox-wrapper {
        margin-left: 0px!important;
    }

    .sns-resource-player-input-wrapper {
        text-align: unset!important;
        padding-left: unset!important;
    }

    #sns-resource-player-btn-save {
        margin-left: 0px!important;
        margin-top: 5px!important;
        margin-bottom: 10px!important;
    }

    #sns-conversation-message-input {
        margin-left: unset!important;
        margin-right: 10px!important;
    }
}


@media only screen and (max-width: 375px) {
    #layout-content-container {
       /* padding: 0px!important; */
    }

    #sns-resource-list-container {
        height: 25vh!important;
    }

    #sns-resource-downloads-container {
        height: 35vh!important;
    }

    #sns-resource-player-answer-content-wrapper {
        height: 80vh!important;
        max-height: 80vh!important;
    }
   
    .sns-resource-player-checkbox-wrapper {
        margin-left: 0px!important;
    }

    .sns-resource-player-input-wrapper {
        text-align: unset!important;
        padding-left: unset!important;
    }

    #sns-conversation-message-input {
        margin-left: unset!important;
        margin-right: 10px!important;
    }
}

@media only screen and (max-width: 320px) {
    #sns-lbl-exception {
        margin-right: 20x!important;
    }
    .sns-game-list-filter {
        width: 55vw!important;
        max-width: 55vw!important;
    }
    #layout-content-container {
       /* padding: 0px!important; */
    }

    #sns-resource-player-answer-content-wrapper {
        height: 95vh!important;
        max-height: 95vh!important;
    }

    .sns-resource-player-checkbox-wrapper {
        margin-left: 0px!important;
    }
    
    
    #sns-conversation-message-input {
        margin-left: unset!important;
        margin-right: 10px!important;
    }

}

/* -------------- Chat Box -------------------- */
.sns-conversation-container {
    height: 60vh;
    max-height: 60vh;
    overflow-y: scroll;
    border-radius: 8px;
    padding: 0.5rem 0.5rem;
    border: 3px solid #ced4da;
}

.sns-conversation-content-container {
    border: 2px solid #dedede;
    /* background-color: #f1f1f1; */
    border-radius: 5px;
    padding: 10px;
    margin: 10px 0;
  }
  
  .sns-conversation-chat-darker {
    border-color: #ccc;
    /* background-color: #ddd; */
  }
  
  .sns-conversation-content-container::after {
    content: "";
    clear: both;
    display: table;
  }
  
  .sns-conversation-img-left {
    float: left;
    max-width: 60px;
    width: 100%;
    margin-right: 20px;
    border-radius: 50%;
  }
  
  .sns-conversation-img-right {
    float: right;
    margin-left: 20px;
    margin-right:0;
    border-radius: 50%;
  }
  
  .sns-conversation-time-right {
    float: right;
    color: #aaa;
  }
  
  .sns-conversation-time-left {
    float: left;
    color: #999;
  }


/* ----------- Resource Player ------------- */
.sns-resource-player-checkbox-wrapper {
    margin-top: 10px;
    margin-bottom: 10px;
}

.sns-resource-player-input-wrapper {
    padding-left: 100px;
    margin-top : 10px;
    margin-bottom:10px;
}

#sns-resource-player-answer-content-wrapper {
    height: 51vh;
    max-height: 51vh;
    margin-top: 1.5vh;
}

.sns-game-setting-lbl {
    margin-right: 30px;
}

#sns-it-start .p-inputtext {
    width:100%;
    max-width: 100%;
}

#sns-it-end .p-inputtext {
    width:100%;
    max-width: 100%;
}

#sns-it-start {
    width:100%!important;
    max-width:100%!important;
    padding: unset!important;
}

#sns-it-end {
    width:100%!important;
    max-width:100%!important;
    padding: unset!important;
}

input#sns-it-qr {
    width: 100%;
    max-width: 100%;
    margin-top: 40px;
}

#sns-lbl {
    margin-right: 10px;
}

#sns-lbl-exception {
    margin-right:10px;
}

#sns-moving-content-wrapper {
    margin-top: 3vh;
}

#sns-wp-header {
    margin: unset!important;
    padding: unset!important;
}

#sns-wp-title {
    padding: unset!important;
}

#sns-panel-wrapper {
    padding: unset!important;
    margin: unset!important;
}

.sns-moving-create-copy {
    margin: 20px 50px;
}

.sns-game-moving {
    width: 50vw;
    max-width: 50vw;
    overflow-y: scroll;
    -ms-overflow-style: none;
}

.sns-gamelist-moving {
    width: 25vw;
    max-width: 25vw;
    overflow-y: scroll;
    -ms-overflow-style: none;
}

.sns-game-moving::-webkit-scrollbar {
    display: none;
}

.sns-game-list-filter-container {
    height: 5vh;
}

.sns-game-list-filter {
    width:20vw;
    max-width: 20vw;
}

span.answer-content {
    margin: 2px 7px 2px 0px;
    padding: 3px 5px;
    color: #46799b;
    background: #e0eaf1;
    white-space: nowrap;
    overflow: hidden;
    border-radius: 2px;
}

/* --------------Game Access Table-------------------- */
.customer-badge {
    border-radius: 2px;
    padding: 0.25em 0.5em;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;
}

.customer-badge.status-qualified {
    background-color: #c8e6c9;
    color: #256029;
}
.customer-badge.status-unqualified {
    background-color: #ffcdd2;
    color: #c63737;
}
.customer-badge.status-negotiation {
    background-color: #feedaf;
    color: #8a5340;
}
.customer-badge.status-new {
    background-color: #b3e5fc;
    color: #23547b;
}
.customer-badge.status-renewal {
    background-color: #eccfff;
    color: #694382;
}
.customer-badge.status-proposal {
    background-color: #ffd8b2;
    color: #805b36;
}
.p-multiselect-representative-option {
    display: inline-block;
    vertical-align: middle;
}
.p-multiselect-representative-option img {
    vertical-align: middle;
    width: 24px;
}
.p-multiselect-representative-option span {
    margin-top: 0.125em;
}
.p-paginator .p-dropdown {
    float: left;
}
.p-paginator .p-paginator-current {
    float: right;
}
.p-progressbar {
    height: 8px;
    background-color: #d8dadc;
}
.p-progressbar .p-progressbar-value {
    background-color: #00acad;
    transition: none;
}
.p-column-filter {
    display: block;
}
.p-column-filter input {
    width: 100%;
}
.p-datatable-globalfilter-container {
    float: right;
}
.p-datatable-globalfilter-container input {
    width: 200px;
}
.p-datepicker {
    min-width: 25em;
}
.p-datepicker td {
    font-weight: 400;
}
.p-datatable.p-datatable-customers .p-datatable-header {
    border: 0 none;
    padding: 12px;
    text-align: left;
    font-size: 20px;
}
.p-datatable.p-datatable-customers .p-paginator {
    border: 0 none;
    padding: 1em;
}
.p-datatable.p-datatable-customers .p-datatable-thead > tr > th {
    border: 0 none;
    text-align: left;
}
.p-datatable.p-datatable-customers .p-datatable-thead > tr > th.p-filter-column {
    border-top: 1px solid #c8c8c8;
}
.p-datatable.p-datatable-customers .p-datatable-tbody > tr > td {
    border: 0 none;
    cursor: auto;
}
.p-datatable.p-datatable-customers .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
}


#game-editor-chips ul.p-inputtext li.p-chips-input-token {
    border: unset!important;
    width: 100%;
}

#start-end-calender .p-inputtext {
    width: 200%;
}

.image-item {
    display: flex;
    margin: 10px 0;
}

.image-item__btn-wrapper {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

/* -------------- Report Download ------------ */

.drawer-wrapper {
    padding: 2px 8px;
    background-color: #e0e0e0;
    border-left: 8px solid #00008c;
}

.icon-asset-wrapper {
    height: 250px;
}

.icon-asset {
    background-color: white;
    height: inherit;
    border-radius: 20%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.vertical-icon {
    display: flex;
    flex-direction: column;
    position: absolute;
    padding-left: 215px;
    padding-top: 15px;
    font-size: 1.8rem;
    cursor: pointer;
}

.lbl-asset-name {
    padding: 8px;
    background-color: #607D8B;
    color: white;
}

.asset-img-icon {
    padding-top: 10px;
}

.icon-asset-wrapper:focus {
    background-color: lightcoral;
}

.video-asset-wrapper {
    height: 170px;
}

.video-asset {
    border-radius: 30px;
    width:97%;
    background-color:#ededed;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.video-player-asset {
    border-radius: 30px;
    height: 180px!important;
}

.video-asset-info {
    position: relative;
}

div.p-col-5.no-padding {
    padding: 0px!important;
}

/* div[id^='pr_id'], div[id*=' pr_id']{
    line-height: 9px!important;
} */

ul.open.jsx-4179805763 {
    background: white;
    z-index: 9999!important;
}


.btn-border-edit {
    width:150px;
    height: 30px;
}

.btn-border-delete {
    width: 150px;
}


button.rhap_button-clear.rhap_repeat-button {
    margin-left: 0px!important;
    font-size: 1.2rem;
    background-color: #607D8B;
    border-radius: 20px;
    border: unset;
    color: white;
    padding-top: 5px;
}

button.rhap_button-clear.rhap_volume-button {
    margin-left: 0px!important;
    font-size: 1.2rem;
    background-color: #607D8B;
    border-radius: 20px;
    border: unset;
    color: white;
    padding-top: 5px;
}

button.rhap_button-clear.rhap_main-controls-button.rhap_play-pause-button {
    font-size: 1.2rem;
    background-color: #607D8B;
    border-radius: 20px;
    border: unset;
    color: white;
    padding-top: 5px;
}

button.rhap_button-clear.rhap_main-controls-button.rhap_forward-button {
    font-size: 1.2rem;
    background-color: #607D8B;
    border-radius: 20px;
    border: unset;
    color: white;
    padding-top: 5px;
}

button.rhap_button-clear.rhap_main-controls-button.rhap_rewind-button {
    font-size: 1.2rem;
    background-color: #607D8B;
    border-radius: 20px;
    border: unset;
    color: white;
    padding-top: 5px;
}

.rstcustom__rowLabel {
    padding: 3px;
    cursor: pointer;
}

.p-lg-25 {
    padding: 0.5em;
}

#sns-dd-lang {
    width: 100%!important;
}

@media screen and (min-width: 992px) {
    .p-lg-25 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    }
    .p-lg-25 {
        width: 20%;
    }
}

.rst__virtualScrollOverride::-webkit-scrollbar {
    display: none!important;
}

.rst__virtualScrollOverride {
    -ms-overflow-style: none!important;  /* IE and Edge */
    scrollbar-width: none!important;  /* Firefox */
}


#sns-wp-instruction-container {
    display: flex;              /* establish flex container */
    flex-direction: column;     /* stack flex items vertically */
    justify-content: center;    /* center items vertically, in this case */
    align-items: center;        /* center items horizontally, in this case */
    height: 300px;
    height: 100%;
    border-left: 0.5px solid lightslategrey;
    background: linear-gradient(270deg, rgba(96,125,139,1) 0%, rgba(143,164,173,1) 0%, rgba(202,212,216,1) 38%, rgba(206,215,219,1) 49%, rgba(255,255,255,1) 77%);
}

.sns-wp-instruction-box {
    width: 500px;
    margin: 5px;
    text-align: center;
    border: 1px solid black;
    padding: 15px;
    font-size: 1.3rem;
}

#media-asset-video {
    border-radius: 20px;
}


.dataview-demo .user-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5em;
    border-bottom: 1px solid #d9dad9;
}
.dataview-demo .user-details > div {
    display: flex;
    align-items: center;
}
.dataview-demo .user-details > div img {
    margin-right: 14px;
}
.dataview-demo .user-detail {
    padding: 0 1em 1em 1em;
    border-bottom: 1px solid #d9dad9;
    margin: 1em;
}
.dataview-demo .p-panel-content {
    padding: 1em;
}

#react-confirm-alert {
    z-index: 1002000;
}

.react-confirm-alert-overlay {
    z-index: 1002000!important;
}

.p-dialog-mask.p-component-overlay.p-dialog-visible.p-dialog-center {
    z-index: 1001990!important;
    opacity: unset!important;
}

.css-df17o1 {
    z-index: 1001995!important; 
}

.css-79elbk {
    position: unset!important;
}

#sns-dialog-os {
    width: 400px!important;
    /* text-align: center!important; */
}

#sns-dialog-os .p-dialog-titlebar {
    background: grey;
    color: white;
}

#sns-dialog-os .p-dialog-footer {
    max-height: 65px!important;
}

#sns-dialog-os .p-dialog-content {
    overflow-x: hidden!important;
    overflow-y: auto!important;
    max-height: 100%;
    height: 40vh;
}

#opacity-5 {
    opacity: 0.5!important
}

#role-wrapper .p-dropdown-items-wrapper {
    width: 100%!important;
    text-align: center!important;
}

#role-wrapper li.p-dropdown-item.p-highlight{
    min-width: 100%!important;
    text-align: center!important;
}