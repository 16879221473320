@media (max-width: 70em) {
	#sns-dialog-org-structure {
		.p-datatable-tbody > tr > td {
			overflow: hidden	;
			text-overflow: ellipsis;
		}
		.p-datatable-responsive .p-datatable-thead>tr>th, .p-datatable-responsive .p-datatable-tfoot>tr>td {
			display: none!important;
		}
	
		.p-datatable-responsive .p-datatable-tbody {
			>tr {
				border: 1px solid #cecece;
				&.p-datatable-row>td {
					text-align: left!important;
					display: block;
					border: 0 none;
					width: 100% !important;
					float: left;
					clear: left;
					.p-column-title {
						padding: .4em;
						min-width: 30%;
						display: inline-block;
						margin: -.4em 1em -.4em -.4em;
						font-weight: bold
					}
			}
			}
		}
		}
}

.org-list {
	.p-dropdown-panel{
		top: 0px!important;
		left: 74px!important;
		border-radius: 0 15px 15px 0px;
	}

	.p-dropdown-label {
		display: none;
	}

	.p-dropdown-trigger {
		top: 0!important;
		padding: unset!important;
		margin-top: unset!important;
		&[aria-expanded="true"]{
			.p-dropdown-trigger-icon{
				transform: rotateZ(270deg)!important;
			}
		}
		
	}
	
}

.rst__moveHandle {
    background: #1ca7ec    url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MiIgaGVpZ2h0PSI0MiI+PGcgc3Ryb2tlPSIjRkZGIiBzdHJva2Utd2lkdGg9IjIuOSIgPjxwYXRoIGQ9Ik0xNCAxNS43aDE0LjQiLz48cGF0aCBkPSJNMTQgMjEuNGgxNC40Ii8+PHBhdGggZD0iTTE0IDI3LjFoMTQuNCIvPjwvZz4KPC9zdmc+')    no-repeat center;
}

.p-sidebar-active {
	overflow-y: scroll;
}


@media screen and (max-width: 1180px) {
    .rst__rowWrapper {
            width: 590px!important;
    }
}

@media screen and (max-width: 994px) {
    .rst__rowWrapper {
            width: 600px!important;
    }
}

@media screen and (max-width: 794px) {
    .rst__rowWrapper {
            width: 420px!important;
    }
}

@media screen and (max-width: 664px) {
    .rst__rowWrapper {
            width: 340px!important;
    }
}

@media screen and (max-width: 1000) {
    #add-admin{
            width: 100%!important;
			height: 100%!important;
			max-height: unset!important;
    }
}


// @media only screen and (max-width:768px) and (vertical-scrollbar:[inside|outside|on-top]) {
// 	/* Rules here */
// }